<template>
  <div class="my-points">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>我的积分</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="board">
        <div class="col-xs-4">
          {{ totalPoint | 0 }}
          <div class="lb">总积分</div>
        </div>
        <div class="col-xs-4">
          {{ consumedPoint | 0 }}
          <div class="lb">消费积分</div>
        </div>
        <div class="col-xs-4">
          {{ points }}
          <div class="lb">剩余积分</div>
        </div>
      </div>
      <!-- <van-tabs v-model="active">
        <van-tab title="积分明细"></van-tab>
        <van-tab title="积分商城"></van-tab>
      </van-tabs> -->
      <ul class="card-list">
        <template v-if="list.length > 0">
          <li class="list-item" v-for="item in list" :key="item.PK_ID">
            <div class="circle">{{ tradeTypeDict[item.trade_type] }}</div>
            <div class="">
              <div>
                {{ item.sRemark | empty }} -流水：{{ item.ssn | empty }}
              </div>
              <div class="date">{{ item.createdate }}</div>
            </div>
            <div class="f-16 ml-auto color-3">{{ item.points }}</div>
          </li>
        </template>
        <template v-else></template>
      </ul>
    </main>
  </div>
</template>
<script>
import { queryPointsForAccount } from '@/api/account.js';
import { formatDate } from '@/utils/index.js';
function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}
export default {
  data() {
    return {
      points: 0,
      consumedPoint: 0,
      totalPoint: 0,
      active: 0,
      list: [],
      tradeTypeDict: { 1: '支', 2: '收' },
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      console.log(this.$store.getters.userInfo);
      this.points =
        this.$store.getters.userInfo.totalpoints -
        this.$store.getters.userInfo.consumedpoints;
      this.totalPoint = this.$store.getters.userInfo.totalpoints;
      this.consumedPoint = this.$store.getters.userInfo.consumedpoints;
      queryPointsForAccount(60).then(res => {
        if (res.Result == true) {
          this.list = res.DataDict['list'];
          this.list.forEach(el => {
            el.createdate = getValidTime(el.createdate);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.board {
  height: rem(180px);
  line-height: rem(180px);
  background: linear-gradient(0deg, #5c57bb 0%, #5d55f4 100%);
  text-align: center;
  font-size: 31px;
  color: #fff;
  margin: rem(20px);
  border-radius: rem(14px);
  .col-xs-4 {
    text-align: center;
    line-height: 1.2rem;
    .lb {
      font-size: 0.2rem;
      line-height: 0.2rem;
    }
  }
}
.card-list {
  padding: rem(20px);
}
.list-item {
  padding: rem(20px);
  height: rem(140px);
  box-sizing: border-box;
  background: #ffffff;
  border-radius: rem(14px);
  box-shadow: 0px 2px 6px 0px rgba(93, 85, 237, 0.15);
  display: flex;
  align-items: center;
  & + .list-item {
    margin-top: rem(20px);
  }
}
.circle {
  width: rem(80px);
  height: rem(80px);
  background: #666;
  border-radius: 50%;
  margin-right: rem(18px);
  line-height: 0.8rem;
  text-align: center;
  font-size: 0.4rem;
  color: #fff;
}
.date {
  font-size: 12px;
  color: #b8b8b8;
  margin-top: rem(40px);
  text-align: right;
}
</style>
